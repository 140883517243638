<template>
  <v-expansion-panel
    class="mt-0 bg-secondary-o-50 mb-3 rounded-lg"
    @click="togglePanel"
  >
    <v-expansion-panel-header class="ps-0">
      <span class="font-weight-bolder font-size-lg ms-5">
        <!-- <v-icon class="mr-3">mdi-{{ headerIcon }}-circle-outline</v-icon> -->
        {{ warehouseDetails.text }}
      </span>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="ps-0">
      <div class="ms-5 border-top">
        <div
          v-if="!serverDataLoaded"
          class="d-flex justify-content-center mt-5"
        >
          <v-progress-circular
            :size="50"
            :width="5"
            color="#7e04b7"
            indeterminate
          ></v-progress-circular>
        </div>
        <form v-else-if="serverDataLoaded" class="form-body mt-5">
          <!-- Allow over receiving -->
          <v-row>
            <v-col>
              <v-checkbox
                v-model="formData.auto_close_asn_once_fully_received"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Auto close asn once fully received
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col md="6" v-if="formData.auto_close_asn_once_fully_received">
              <v-autocomplete
                v-model="formData.auto_close_asn_once_fully_received_data"
                label="ASN types"
                dense
                outlined
                rounded
                clearable
                multiple
                item-text="text"
                item-value="index"
                :items="serverData.asn_types"
                :error-messages="auto_close_asn_once_fully_received_dataErrors"
                @input="
                  $v.formData.auto_close_asn_once_fully_received_data.$touch()
                "
                @blur="
                  $v.formData.auto_close_asn_once_fully_received_data.$touch()
                "
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox
                v-model="formData.is_allow_over_receiving"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Allow over receiving
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col class="mt-2" md="6" v-if="serverData">
              <v-row v-if="formData.is_allow_over_receiving" class="ms-3">
                <v-col md="6">
                  <v-select
                    v-model="formData.is_allow_over_receiving_apply_rule_type"
                    label="Choose rule"
                    dense
                    outlined
                    rounded
                    item-text="text"
                    item-value="index"
                    :items="serverData.apply_rule_types"
                    :error-messages="
                      is_allow_over_receiving_apply_rule_typeErrors
                    "
                    @input="
                      $v.formData.is_allow_over_receiving_apply_rule_type.$touch()
                    "
                    @blur="
                      $v.formData.is_allow_over_receiving_apply_rule_type.$touch()
                    "
                  />
                </v-col>
                <v-col
                  md="6"
                  v-if="
                    formData.is_allow_over_receiving_apply_rule_type !==
                      'all' &&
                    formData.is_allow_over_receiving_apply_rule_type !== null
                  "
                >
                  <v-select
                    v-model="formData.is_allow_over_receiving_customers"
                    label="Choose customer(s)"
                    multiple
                    dense
                    outlined
                    item-text="text"
                    item-value="index"
                    rounded
                    :items="serverData.clients"
                    :error-messages="is_allow_over_receiving_customersErrors"
                    @input="
                      $v.formData.is_allow_over_receiving_customers.$touch()
                    "
                    @blur="
                      $v.formData.is_allow_over_receiving_customers.$touch()
                    "
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- receiving location -->
          <v-row v-if="serverData">
            <v-col md="6">
              <v-autocomplete
                v-model="formData.receivingLocation"
                :value="stagingWarehouseSearch"
                label="Default staging location"
                dense
                outlined
                :loading="stagingWarehouseLoading"
                rounded
                clearable
                item-text="name"
                item-value="id"
                :search-input.sync="stagingWarehouseSearch"
                :items="serverData.stagingLocations"
                @keyup="() => debounceSearch('searchStagingLocation')"
                @change="clearDefaultWarehouseLocation"
                :error-messages="stagingLocationErrors"
                @input="$v.formData.receivingLocation.$touch()"
                @blur="$v.formData.receivingLocation.$touch()"
              />
              <!--              @keydown="searchStagingLocation"-->
            </v-col>
          </v-row>
          <!-- transfer location -->
          <v-row v-if="serverData">
            <v-col md="6">
              <v-autocomplete
                v-model="formData.transferLocation"
                :value="transferLocationSearch"
                label="Default transfer location"
                dense
                outlined
                clearable
                :loading="transferLocationLoading"
                rounded
                item-text="name"
                item-value="id"
                :search-input.sync="transferLocationSearch"
                :items="serverData.transferLocations"
                @keyup="() => debounceSearch('searchTransferLocation')"
                @change="clearDefaultTransferLocation"
                :error-messages="transferLocationErrors"
                @input="$v.formData.transferLocation.$touch()"
                @blur="$v.formData.transferLocation.$touch()"
              />
              <!--              @keydown="searchTransferLocation"-->
            </v-col>
          </v-row>
          <!-- damage location -->
          <v-row v-if="serverData">
            <v-col md="6">
              <v-autocomplete
                v-model="formData.damageLocation"
                :value="damageLocationSearch"
                label="Default damage location"
                dense
                outlined
                clearable
                :loading="damageLocationLoading"
                rounded
                item-text="name"
                item-value="id"
                :search-input.sync="damageLocationSearch"
                :items="serverData.damageLocations"
                @keyup="() => debounceSearch('searchDamageLocation')"
                @change="clearDefaultDamageLocation"
                :error-messages="damageLocationErrors"
                @input="$v.formData.damageLocation.$touch()"
                @blur="$v.formData.damageLocation.$touch()"
              />
              <!--              @keydown="searchDamageLocation"-->
            </v-col>
          </v-row>
          <v-row v-if="serverData">
            <v-col>
              <v-checkbox
                v-model="
                  formData.is_allow_user_to_decide_receiving_type_while_receiving
                "
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Allow user to decide receiving type while receiving
                  </span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row v-if="serverData">
            <v-col>
              <v-checkbox
                v-model="formData.is_upfront_tote_scan_enabled_when_receiving"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Upfront tote scan enabled while receiving
                  </span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <!-- Submit -->
          <v-row class="justify-center mb-3">
            <v-col md="2" class="justify-content-end">
              <button
                class="btn btn-info px-5 py-3 ls1"
                @click.prevent="submitConfiguration"
              >
                Submit
              </button>
            </v-col>
          </v-row>
        </form>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import Swal from "sweetalert2";
export default {
  name: "ExpansionPanel",
  mixins: [validationMixin],
  props: {
    warehouseDetails: { type: Object, required: true },
  },
  components: {},
  data() {
    return {
      debounce: null,
      expanded: false,
      serverData: null,
      serverDataLoaded: false,
      stagingWarehouseSearch: "",
      transferLocationSearch: "",
      damageLocationSearch: "",
      stagingWarehouseLoading: false,
      transferLocationLoading: false,
      damageLocationLoading: false,
      formData: {
        auto_close_asn_once_fully_received: false,
        auto_close_asn_once_fully_received_data: [],

        is_allow_over_receiving: false,
        is_allow_over_receiving_apply_rule_type: null,
        is_allow_over_receiving_customers: [],

        is_allow_user_to_decide_receiving_type_while_receiving: false,

        is_generate_putaway_task_when_asn_received: false,
        is_generate_putaway_task_when_asn_received_apply_rule_type: null,
        is_generate_putaway_task_when_asn_received_customers: [],

        is_allow_same_tote_for_different_skus: false,
        is_allow_same_tote_for_different_skus_apply_rule_type: null,
        is_allow_same_tote_for_different_skus_customers: [],

        is_allow_same_tote_for_multiple_asns: false,
        allowSameToteAsnRtules: null,
        is_allow_same_tote_for_multiple_asns_customers: [],

        is_validate_weight_and_dimension_before_receiving: false,
        is_validate_weight_and_dimension_before_receiving_apply_rule_type: null,
        is_validate_weight_and_dimension_before_receiving_customers: [],

        receivingLocation: null,
        transferLocation: null,
        damageLocation: null,

        is_upfront_tote_scan_enabled_when_receiving: false,
      },
    };
  },
  validations() {
    return {
      formData: {
        is_allow_over_receiving_apply_rule_type: {
          required: requiredIf(function () {
            return this.formData.is_allow_over_receiving;
          }),
        },
        is_allow_over_receiving_customers: {
          required: requiredIf(function () {
            return (
              (this.formData.is_allow_over_receiving &&
                this.formData.is_allow_over_receiving_apply_rule_type ===
                  "only") ||
              this.formData.is_allow_over_receiving_apply_rule_type === "except"
            );
          }),
        },
        is_generate_putaway_task_when_asn_received_apply_rule_type: {
          required: requiredIf(function () {
            return this.formData.is_generate_putaway_task_when_asn_received;
          }),
        },
        is_generate_putaway_task_when_asn_received_customers: {
          required: requiredIf(function () {
            return (
              (this.formData.is_generate_putaway_task_when_asn_received &&
                this.formData
                  .is_generate_putaway_task_when_asn_received_apply_rule_type ===
                  "only") ||
              this.formData
                .is_generate_putaway_task_when_asn_received_apply_rule_type ===
                "except"
            );
          }),
        },
        is_allow_same_tote_for_different_skus_apply_rule_type: {
          required: requiredIf(function () {
            return this.formData.is_allow_same_tote_for_different_skus;
          }),
        },
        is_allow_same_tote_for_different_skus_customers: {
          required: requiredIf(function () {
            return (
              (this.formData.is_allow_same_tote_for_different_skus &&
                this.formData
                  .is_allow_same_tote_for_different_skus_apply_rule_type ===
                  "only") ||
              this.formData
                .is_allow_same_tote_for_different_skus_apply_rule_type ===
                "except"
            );
          }),
        },
        is_allow_same_tote_for_multiple_asns_apply_rule_type: {
          required: requiredIf(function () {
            return this.formData.is_allow_same_tote_for_multiple_asns;
          }),
        },
        is_allow_same_tote_for_multiple_asns_customers: {
          required: requiredIf(function () {
            return (
              (this.formData.is_allow_same_tote_for_multiple_asns &&
                this.formData
                  .is_allow_same_tote_for_multiple_asns_apply_rule_type ===
                  "only") ||
              this.formData
                .is_allow_same_tote_for_multiple_asns_apply_rule_type ===
                "except"
            );
          }),
        },
        is_validate_weight_and_dimension_before_receiving_apply_rule_type: {
          required: requiredIf(function () {
            return this
              .formData.is_validate_weight_and_dimension_before_receiving;
          }),
        },
        is_validate_weight_and_dimension_before_receiving_customers: {
          required: requiredIf(function () {
            return (
              (this.formData
                .is_validate_weight_and_dimension_before_receiving &&
                this.formData
                  .is_validate_weight_and_dimension_before_receiving_apply_rule_type ===
                  "only") ||
              this.formData
                .is_validate_weight_and_dimension_before_receiving_apply_rule_type ===
                "except"
            );
          }),
        },
        receivingLocation: { required },
        transferLocation: { required },
        damageLocation: { required },
        auto_close_asn_once_fully_received_data: {
          required: requiredIf(function () {
            return this.formData.auto_close_asn_once_fully_received;
          }),
        },
      },
    };
  },
  methods: {
    togglePanel: function () {
      if (!this.expanded) {
        this.loadDataFromServer();
      }
      this.expanded = !this.expanded;
    },

    loadDataFromServer: function () {
      this.serverDataLoaded = false;
      const payload = {
        type: "receiving",
        warehouse_id: this.warehouseDetails.index,
      };
      return new Promise((resolve) => {
        ApiService.post(
          `/warehouse_management/main_configuration/create`,
          payload
        )
          .then(({ data }) => {
            this.serverData = data;
            this.setConfigurationData(data.configurations.details);
            this.formData.receivingLocation =
              data.configurations.default_location_id;
            this.formData.transferLocation =
              data.configurations.default_transfer_location_id;
            this.formData.damageLocation =
              data.configurations.default_damage_location_id;
            this.serverData.stagingLocations = data.locations;
            this.serverData.transferLocations = data.transfer_locations;
            this.serverData.damageLocations = data.damage_locations;
            this.serverDataLoaded = true;
            resolve(data);
          })
          .catch();
      });
    },

    setConfigurationData: function (data) {
      data.forEach((item) => {
        if (item.value) {
          this.formData[item.key] = !!item.value;
        }
        if (item.key === "is_allow_over_receiving") {
          this.formData.is_allow_over_receiving_customers = item.customers;
          this.formData.is_allow_over_receiving_apply_rule_type =
            item.apply_rule_type;
        } else if (item.key === "is_generate_putaway_task_when_asn_received") {
          this.formData.is_generate_putaway_task_when_asn_received_customers =
            item.customers;
          this.formData.is_generate_putaway_task_when_asn_received_apply_rule_type =
            item.apply_rule_type;
        } else if (item.key === "is_allow_same_tote_for_different_skus") {
          this.formData.is_allow_same_tote_for_different_skus_customers =
            item.customers;
          this.formData.is_allow_same_tote_for_different_skus_apply_rule_type =
            item.apply_rule_type;
        } else if (item.key === "is_allow_same_tote_for_multiple_asns") {
          this.formData.is_allow_same_tote_for_multiple_asns_customers =
            item.customers;
          this.formData.is_allow_same_tote_for_multiple_asns_apply_rule_type =
            item.apply_rule_type;
        } else if (
          item.key === "is_validate_weight_and_dimension_before_receiving"
        ) {
          this.formData.is_validate_weight_and_dimension_before_receiving_customers =
            item.customers;
          this.formData.is_validate_weight_and_dimension_before_receiving_apply_rule_type =
            item.apply_rule_type;
        } else if (item.key === "auto_close_asn_once_fully_received") {
          this.formData.auto_close_asn_once_fully_received_data = item.data;
        }
      });
    },

    handleFormValidation: function (fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },

    submitConfiguration: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = this.formData;
        const payload = {
          type: "receiving",
          warehouse_id: this.warehouseDetails.index,
          default_location_id: data.receivingLocation,
          default_transfer_location_id: data.transferLocation,
          default_damage_location_id: data.damageLocation,
          configurations: [
            {
              key: "is_allow_over_receiving",
              value: data.is_allow_over_receiving,
              apply_rule_type: data.is_allow_over_receiving_apply_rule_type,
              customers:
                data.is_allow_over_receiving_apply_rule_type === "all"
                  ? []
                  : data.is_allow_over_receiving_customers,
            },
            {
              key: "is_allow_user_to_decide_receiving_type_while_receiving",
              value:
                data.is_allow_user_to_decide_receiving_type_while_receiving,
              apply_rule_type: "all",
              customers: [],
            },
            {
              key: "is_upfront_tote_scan_enabled_when_receiving",
              value: data.is_upfront_tote_scan_enabled_when_receiving,
              apply_rule_type: "all",
              customers: [],
            },
            {
              key: "is_generate_putaway_task_when_asn_received",
              value: data.is_generate_putaway_task_when_asn_received,
              apply_rule_type:
                data.is_generate_putaway_task_when_asn_received_apply_rule_type,
              customers:
                data.is_generate_putaway_task_when_asn_received_apply_rule_type ===
                "all"
                  ? []
                  : data.is_generate_putaway_task_when_asn_received_customers,
            },
            {
              key: "is_allow_same_tote_for_different_skus",
              value: data.is_allow_same_tote_for_different_skus,
              apply_rule_type:
                data.is_allow_same_tote_for_different_skus_apply_rule_type,
              customers:
                data.is_allow_same_tote_for_different_skus_apply_rule_type ===
                "all"
                  ? []
                  : data.is_allow_same_tote_for_different_skus_customers,
            },
            {
              key: "is_allow_same_tote_for_multiple_asns",
              value: data.is_allow_same_tote_for_multiple_asns,
              apply_rule_type:
                data.is_allow_same_tote_for_multiple_asns_apply_rule_type,
              customers:
                data.is_allow_same_tote_for_multiple_asns_apply_rule_type ===
                "all"
                  ? []
                  : data.is_allow_same_tote_for_multiple_asns_customers,
            },
            {
              key: "is_validate_weight_and_dimension_before_receiving",
              value: data.is_validate_weight_and_dimension_before_receiving,
              apply_rule_type:
                data.is_validate_weight_and_dimension_before_receiving_apply_rule_type,
              customers:
                data.is_validate_weight_and_dimension_before_receiving_apply_rule_type ===
                "all"
                  ? []
                  : data.is_validate_weight_and_dimension_before_receiving_customers,
            },
            {
              key: "auto_close_asn_once_fully_received",
              value: data.auto_close_asn_once_fully_received,
              apply_rule_type: "all",
              customers: [],
              data: this.formData.auto_close_asn_once_fully_received_data,
            },
          ],
        };
        this.$store.commit(SET_PAGE_LOADING, true);
        return new Promise(() => {
          ApiService.post(
            `/warehouse_management/main_configuration/store`,
            payload
          )
            .then(() => {
              Swal.fire({
                title: "Updated",
                text: "The Configuration settings are updated",
                icon: "success",
                showConfirmButton: false,
                timer: 2500,
              });
              // this.expanded = false;
            })
            .catch()
            .finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
        });
      }
    },
    debounceSearch(clbkFn) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this[clbkFn]();
      }, 600);
    },
    searchStagingLocation() {
      setTimeout(() => {
        if (this.stagingWarehouseSearch) {
          this.stagingWarehouseLoading = true;
          const payload = {
            warehouse_id: this.warehouseDetails.index,
            q: this.stagingWarehouseSearch,
            location_type: "staging",
          };
          new Promise((resolve) => {
            ApiService.post(`/wms/warehouses/search-location`, payload).then(
              ({ data }) => {
                this.serverData.stagingLocations = data.locations;
                this.stagingWarehouseLoading = false;
                resolve(data);
              }
            );
          });
        }
      }, 200);
    },

    searchTransferLocation() {
      setTimeout(() => {
        if (this.transferLocationSearch) {
          this.transferLocationLoading = true;
          const payload = {
            warehouse_id: this.warehouseDetails.index,
            q: this.transferLocationSearch,
            location_type: "transfer",
          };
          new Promise((resolve) => {
            ApiService.post(`/wms/warehouses/search-location`, payload).then(
              ({ data }) => {
                this.serverData.transferLocations = data.locations;
                this.transferLocationLoading = false;
                resolve(data);
              }
            );
          });
        }
      }, 200);
    },
    searchDamageLocation() {
      setTimeout(() => {
        if (this.damageLocationSearch) {
          this.damageLocationLoading = true;
          const payload = {
            warehouse_id: this.warehouseDetails.index,
            q: this.damageLocationSearch,
            location_type: "damage_type",
          };
          new Promise((resolve) => {
            ApiService.post(`/wms/warehouses/search-location`, payload).then(
              ({ data }) => {
                this.serverData.damageLocations = data.locations;
                this.damageLocationLoading = false;
                resolve(data);
              }
            );
          });
        }
      }, 200);
    },

    clearDefaultWarehouseLocation() {
      if (!this.stagingWarehouseSearch && !this.formData.receivingLocation) {
        this.serverData.stagingLocations = [];
      }
    },

    clearDefaultTransferLocation() {
      if (!this.transferLocationSearch && !this.formData.transferLocation) {
        this.serverData.transferLocations = [];
      }
    },

    clearDefaultDamageLocation() {
      if (!this.damageLocationSearch && !this.formData.damageLocation) {
        this.serverData.damageLocations = [];
      }
    },
  },
  computed: {
    headerIcon: function () {
      return this.expanded ? "minus" : "plus";
    },
    is_allow_over_receiving_apply_rule_typeErrors: function () {
      return this.handleFormValidation(
        "is_allow_over_receiving_apply_rule_type",
        this
      );
    },
    is_allow_over_receiving_customersErrors: function () {
      return this.handleFormValidation(
        "is_allow_over_receiving_customers",
        this
      );
    },
    is_generate_putaway_task_when_asn_received_apply_rule_typeErrors:
      function () {
        return this.handleFormValidation(
          "is_generate_putaway_task_when_asn_received_apply_rule_type",
          this
        );
      },
    is_generate_putaway_task_when_asn_received_customersErrors: function () {
      return this.handleFormValidation(
        "is_generate_putaway_task_when_asn_received_customers",
        this
      );
    },

    is_allow_same_tote_for_different_skus_apply_rule_typeErrors: function () {
      return this.handleFormValidation(
        "is_allow_same_tote_for_different_skus_apply_rule_type",
        this
      );
    },
    is_allow_same_tote_for_different_skus_customersSelectErrors: function () {
      return this.handleFormValidation(
        "is_allow_same_tote_for_different_skus_customers",
        this
      );
    },
    is_allow_same_tote_for_multiple_asns_apply_rule_typeErrors: function () {
      return this.handleFormValidation(
        "is_allow_same_tote_for_multiple_asns_apply_rule_type",
        this
      );
    },
    is_allow_same_tote_for_multiple_asns_customersSelectErrors: function () {
      return this.handleFormValidation(
        "is_allow_same_tote_for_multiple_asns_customers",
        this
      );
    },
    is_validate_weight_and_dimension_before_receiving_apply_rule_typeErrors:
      function () {
        return this.handleFormValidation(
          "is_validate_weight_and_dimension_before_receiving_apply_rule_type",
          this
        );
      },
    is_validate_weight_and_dimension_before_receiving_customersSelectErrors:
      function () {
        return this.handleFormValidation(
          "is_validate_weight_and_dimension_before_receiving_customers",
          this
        );
      },
    stagingLocationErrors: function () {
      return this.handleFormValidation("receivingLocation", this);
    },
    transferLocationErrors: function () {
      return this.handleFormValidation("transferLocation", this);
    },
    damageLocationErrors: function () {
      return this.handleFormValidation("damageLocation", this);
    },
    auto_close_asn_once_fully_received_dataErrors: function () {
      return this.handleFormValidation(
        "auto_close_asn_once_fully_received_data",
        this
      );
    },
  },
};
</script>

<template>
  <v-expansion-panel
    class="mt-0 bg-secondary-o-50 mb-3 rounded-lg"
    @click="togglePanel"
  >
    <v-expansion-panel-header class="ps-0">
      <span class="font-weight-black font-size-lg ms-5">
        {{ warehouseDetails.text }}
      </span>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="ps-0">
      <div class="ms-5 border-top">
        <div
          v-if="!serverDataLoaded"
          class="d-flex justify-content-center mt-5"
        >
          <v-progress-circular
            :size="50"
            :width="5"
            color="#7e04b7"
            indeterminate
          ></v-progress-circular>
        </div>
        <form v-else-if="serverDataLoaded" class="form-body mt-5">
          <!-- ZBL printer address -->
          <v-row class="mt-0 align center">
            <v-col md="3">
              <v-checkbox
                v-model="formData.zbl_printer_address"
                dense
                color="#7e04b7"
                hide-details
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    ZBL printer address
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col class="ms-3" md="8" v-if="formData.zbl_printer_address">
              <!--              <v-row v-if="formData.zbl_printer_address" class="ms-3">-->
              <!--              <v-row class="ms-3">-->
              <!--                <v-col md="6">-->
              <v-text-field
                v-model="formData.zbl_printer_address_option"
                label="Address"
                dense
                outlined
                rounded
                :error-messages="zbl_printer_address_option_error"
                @input="$v.formData.zbl_printer_address_option.$touch()"
                @blur="$v.formData.zbl_printer_address_option.$touch()"
              />
              <!--                </v-col>-->
              <!--              </v-row>-->
            </v-col>
          </v-row>

          <!-- Submit -->
          <v-row class="justify-center mb-3">
            <v-col md="2" class="justify-content-end">
              <button
                class="btn btn-info px-5 py-3 ls1"
                @click.prevent="submitConfiguration"
              >
                Submit
              </button>
            </v-col>
          </v-row>
        </form>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import Swal from "sweetalert2";
import { requiredIf } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  name: "ExpansionPanel",
  mixins: [validationMixin],
  props: {
    warehouseDetails: { type: Object, required: true },
  },
  validations: {
    formData: {
      zbl_printer_address_option: {
        required: requiredIf(function () {
          return this.formData.zbl_printer_address;
        }),
      },
    },
  },
  data() {
    return {
      expanded: false,
      // serverData: null,
      serverDataLoaded: false,

      formData: {
        zbl_printer_address: false,
        zbl_printer_address_option: null,
      },
    };
  },

  methods: {
    togglePanel: function () {
      if (!this.expanded) {
        this.loadDataFromServer();
      }
      this.expanded = !this.expanded;
    },

    loadDataFromServer: function () {
      this.serverDataLoaded = false;
      const payload = {
        type: "print",
        warehouse_id: this.warehouseDetails.index,
      };
      return new Promise((resolve) => {
        ApiService.post(
          `/warehouse_management/main_configuration/create`,
          payload
        )
          .then(({ data }) => {
            // this.serverData = data;
            this.setConfigurationData(data.configurations.details);
            this.serverDataLoaded = true;
            resolve(data);
          })
          .catch();
      });
    },

    setConfigurationData: function (data) {
      this.formData = {
        zbl_printer_address: !!data[0].value,
        zbl_printer_address_option: data[0].value ? data[0].value : null,
      };
      data.forEach((item) => {
        this.formData[item.key] = !!item.value;
        if (item.key === "zbl_printer_address") {
          this.formData.zbl_printer_address_option = item.value
            ? item.value
            : null;
        }
      });
    },

    submitConfiguration: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = this.formData;
        const payload = {
          type: "print",
          warehouse_id: this.warehouseDetails.index,
          configurations: [
            {
              key: "zbl_printer_address",
              value: data.zbl_printer_address
                ? data.zbl_printer_address_option
                : null,
              apply_rule_type: "all",
              customers: [],
            },
          ],
        };
        this.$store.commit(SET_PAGE_LOADING, true);
        ApiService.post(
          `/warehouse_management/main_configuration/store`,
          payload
        )
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: "The Configuration settings are updated",
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
          })
          .finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      }
    },

    handleFormValidation: function (fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].url && errors.push("This url is invalid");
      }
      if ("numeric" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
  },

  computed: {
    zbl_printer_address_option_error: function () {
      return this.handleFormValidation("zbl_printer_address_option");
    },
  },
};
</script>

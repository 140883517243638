<template>
  <v-expansion-panel
    class="mt-0 bg-secondary-o-50 mb-3 rounded-lg"
    @click="togglePanel"
  >
    <v-expansion-panel-header class="ps-0">
      <span class="font-weight-black font-size-lg ms-5">
        {{ warehouseDetails.text }}
      </span>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="ps-0">
      <div class="ms-5 border-top">
        <div
          v-if="!serverDataLoaded"
          class="d-flex justify-content-center mt-5"
        >
          <v-progress-circular
            :size="50"
            :width="5"
            color="#7e04b7"
            indeterminate
          ></v-progress-circular>
        </div>
        <form v-else-if="serverDataLoaded" class="form-body mt-5">
          <!-- Allow over receiving -->
          <v-row>
            <v-col md="4">
              <v-checkbox
                v-model="formData.is_scan_each_unit_when_putaway"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="mt-2 font-size-md font-weight-medium">
                    Scan each unit when putaway
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col class="mt-2" md="8" v-if="serverData">
              <v-row
                v-if="formData.is_scan_each_unit_when_putaway"
                class="ms-3"
              >
                <v-col md="6">
                  <v-select
                    v-model="
                      formData.is_scan_each_unit_when_putaway_apply_rule_type
                    "
                    label="Choose Rule"
                    dense
                    outlined
                    rounded
                    item-text="text"
                    item-value="index"
                    :items="serverData.apply_rule_types"
                    :error-messages="
                      is_scan_each_unit_when_putaway_apply_rule_typeErrors
                    "
                    @input="
                      $v.formData.is_scan_each_unit_when_putaway_apply_rule_type.$touch()
                    "
                    @blur="
                      $v.formData.is_scan_each_unit_when_putaway_apply_rule_type.$touch()
                    "
                  />
                </v-col>
                <v-col
                  md="6"
                  v-if="
                    formData.is_scan_each_unit_when_putaway_apply_rule_type !==
                      'all' &&
                    formData.is_scan_each_unit_when_putaway_apply_rule_type !==
                      null
                  "
                >
                  <v-select
                    v-model="formData.is_scan_each_unit_when_putaway_customers"
                    label="Choose Customer(s)"
                    multiple
                    dense
                    outlined
                    item-text="text"
                    item-value="index"
                    rounded
                    :items="serverData.clients"
                    :error-messages="
                      is_scan_each_unit_when_putaway_customersErrors
                    "
                    @input="
                      $v.formData.is_scan_each_unit_when_putaway_customers.$touch()
                    "
                    @blur="
                      $v.formData.is_scan_each_unit_when_putaway_customers.$touch()
                    "
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- Allow Putaway Across Zones -->
          <v-row class="mt-0">
            <v-col md="4">
              <v-checkbox
                v-model="formData.is_allow_putaway_across_zones"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="mt-2 font-size-md font-weight-medium">
                    Allow Putaway Across Zones
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col class="mt-2" md="8" v-if="serverData">
              <v-row v-if="formData.is_allow_putaway_across_zones" class="ms-3">
                <v-col md="6">
                  <v-select
                    v-model="
                      formData.is_allow_putaway_across_zones_apply_rule_type
                    "
                    label="Choose Rule"
                    dense
                    outlined
                    rounded
                    item-text="text"
                    item-value="index"
                    :items="serverData.apply_rule_types"
                    :error-messages="
                      is_allow_putaway_across_zones_apply_rule_typeErrors
                    "
                    @input="
                      $v.formData.is_allow_putaway_across_zones_apply_rule_type.$touch()
                    "
                    @blur="
                      $v.formData.is_allow_putaway_across_zones_apply_rule_type.$touch()
                    "
                  />
                </v-col>
                <v-col
                  md="6"
                  v-if="
                    formData.is_allow_putaway_across_zones_apply_rule_type !==
                      'all' &&
                    formData.is_allow_putaway_across_zones_apply_rule_type !==
                      null
                  "
                >
                  <v-select
                    v-model="formData.is_allow_putaway_across_zones_customers"
                    label="Choose Customer(s)"
                    multiple
                    dense
                    outlined
                    item-text="text"
                    item-value="index"
                    rounded
                    :items="serverData.clients"
                    :error-messages="
                      is_allow_putaway_across_zones_customersErrors
                    "
                    @input="
                      $v.formData.is_allow_putaway_across_zones_customers.$touch()
                    "
                    @blur="
                      $v.formData.is_allow_putaway_across_zones_customers.$touch()
                    "
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- Allow to split Putaway task -->
          <v-row class="mt-0">
            <v-col md="4">
              <v-checkbox
                v-model="formData.is_allow_to_split_putaway_task"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="mt-2 font-size-md font-weight-medium">
                    Allow to split Putaway Task
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col class="mt-2" md="8" v-if="serverData">
              <v-row
                v-if="formData.is_allow_to_split_putaway_task"
                class="ms-3"
              >
                <v-col md="6">
                  <v-select
                    v-model="
                      formData.is_allow_to_split_putaway_task_apply_rule_type
                    "
                    label="Choose Rule"
                    dense
                    outlined
                    rounded
                    item-text="text"
                    item-value="index"
                    :items="serverData.apply_rule_types"
                    :error-messages="
                      is_allow_to_split_putaway_task_apply_rule_typeErrors
                    "
                    @input="
                      $v.formData.is_allow_to_split_putaway_task_apply_rule_type.$touch()
                    "
                    @blur="
                      $v.formData.is_allow_to_split_putaway_task_apply_rule_type.$touch()
                    "
                  />
                </v-col>
                <v-col
                  md="6"
                  v-if="
                    formData.is_allow_to_split_putaway_task_apply_rule_type !==
                      'all' &&
                    formData.is_allow_to_split_putaway_task_apply_rule_type !==
                      null
                  "
                >
                  <v-select
                    v-model="formData.is_allow_to_split_putaway_task_customers"
                    label="Choose Customer(s)"
                    multiple
                    dense
                    outlined
                    item-text="text"
                    item-value="index"
                    rounded
                    :items="serverData.clients"
                    :error-messages="
                      is_allow_to_split_putaway_task_customersErrors
                    "
                    @input="
                      $v.formData.is_allow_to_split_putaway_task_customers.$touch()
                    "
                    @blur="
                      $v.formData.is_allow_to_split_putaway_task_customers.$touch()
                    "
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- Should preserve tote after putaway -->
          <v-row class="mt-0">
            <v-col md="6">
              <v-checkbox
                v-model="formData.should_preserve_tote_after_putaway"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="mt-2 font-size-md font-weight-medium">
                    Should preserve tote after putaway
                  </span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <!-- location -->
          <v-row>
            <v-col md="6">
              <v-autocomplete
                v-model="defaultStagingLocation"
                label="Default Missing location"
                dense
                outlined
                rounded
                clearable
                item-text="name"
                item-value="id"
                :items="serverData.locations"
                :loading="warehouseLoading"
                @change="clearDefaultMissingLocation"
                :search-input.sync="warehouseSearch"
                @keydown="searchWarehouseLocation"
                :error-messages="stagingLocationError"
              />
            </v-col>
          </v-row>
          <!-- Submit -->
          <v-row class="justify-center mb-3">
            <v-col md="2" class="justify-content-end">
              <!-- <v-btn dark color="purple" @click="submitConfiguration">
                Submit
              </v-btn> -->
              <button
                class="btn btn-info px-5 py-3 ls1"
                @click.prevent="submitConfiguration"
              >
                Submit
              </button>
            </v-col>
          </v-row>
        </form>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  // required,
  requiredIf,
} from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import Swal from "sweetalert2";

export default {
  name: "ExpansionPanel",
  mixins: [validationMixin],
  props: {
    warehouseDetails: { type: Object, required: true },
  },
  components: {},
  data() {
    return {
      expanded: false,
      serverData: null,
      serverDataLoaded: false,
      warehouseSearch: "",
      warehouseLoading: false,
      stagingLocationError: null,
      defaultStagingLocation: null,
      formData: {
        is_scan_each_unit_when_putaway: false,
        is_scan_each_unit_when_putaway_apply_rule_type: null,
        is_scan_each_unit_when_putaway_customers: [],

        is_allow_putaway_across_zones: false,
        is_allow_putaway_across_zones_apply_rule_type: null,
        is_allow_putaway_across_zones_customers: [],

        is_allow_to_split_putaway_task: false,
        is_allow_to_split_putaway_task_apply_rule_type: null,
        is_allow_to_split_putaway_task_customers: [],

        stagingLocation: null,
        should_preserve_tote_after_putaway: false,
      },
    };
  },
  validations: {
    formData: {
      is_scan_each_unit_when_putaway_apply_rule_type: {
        required: requiredIf(function () {
          return this.formData.is_scan_each_unit_when_putaway;
        }),
      },
      is_scan_each_unit_when_putaway_customers: {
        required: requiredIf(function () {
          return (
            (this.formData.is_scan_each_unit_when_putaway &&
              this.formData.is_scan_each_unit_when_putaway_apply_rule_type ===
                "only") ||
            this.formData.is_scan_each_unit_when_putaway_apply_rule_type ===
              "except"
          );
        }),
      },

      is_allow_putaway_across_zones_apply_rule_type: {
        required: requiredIf(function () {
          return this.formData.is_allow_putaway_across_zones;
        }),
      },
      is_allow_putaway_across_zones_customers: {
        required: requiredIf(function () {
          return (
            (this.formData.is_allow_putaway_across_zones &&
              this.formData.is_allow_putaway_across_zones_apply_rule_type ===
                "only") ||
            this.formData.is_allow_putaway_across_zones_apply_rule_type ===
              "except"
          );
        }),
      },

      is_allow_to_split_putaway_task_apply_rule_type: {
        required: requiredIf(function () {
          return this.formData.is_allow_to_split_putaway_task;
        }),
      },
      is_allow_to_split_putaway_task_customers: {
        required: requiredIf(function () {
          return (
            (this.formData.is_allow_to_split_putaway_task &&
              this.formData.is_allow_to_split_putaway_task_apply_rule_type ===
                "only") ||
            this.formData.is_allow_to_split_putaway_task_apply_rule_type ===
              "except"
          );
        }),
      },
      // stagingLocation: { required },
    },
  },
  methods: {
    togglePanel: function () {
      if (!this.expanded) {
        this.loadDataFromServer();
      }
      this.expanded = !this.expanded;
    },

    loadDataFromServer: function () {
      this.serverDataLoaded = false;
      const payload = {
        type: "putaway",
        warehouse_id: this.warehouseDetails.index,
      };
      return new Promise((resolve) => {
        ApiService.post(
          `/warehouse_management/main_configuration/create`,
          payload
        )
          .then(({ data }) => {
            this.serverData = data;
            this.setConfigurationData(data.configurations.details);
            this.defaultStagingLocation =
              data.configurations.default_location_id;
            this.serverDataLoaded = true;
            resolve(data);
          })
          .catch();
      });
    },

    setConfigurationData: function (data) {
      data.forEach((item) => {
        this.formData[item.key] = !!item.value;
        if (item.key === "is_scan_each_unit_when_putaway") {
          this.formData.is_scan_each_unit_when_putaway_apply_rule_type =
            item.apply_rule_type;
          this.formData.is_scan_each_unit_when_putaway_customers =
            item.customers;
        } else if (item.key === "is_allow_putaway_across_zones") {
          this.formData.is_allow_putaway_across_zones_apply_rule_type =
            item.apply_rule_type;
          this.formData.is_allow_putaway_across_zones_customers =
            item.customers;
        } else if (item.key === "is_allow_to_split_putaway_task") {
          this.formData.is_allow_to_split_putaway_task_apply_rule_type =
            item.apply_rule_type;
          this.formData.is_allow_to_split_putaway_task_customers =
            item.customers;
        }
      });
    },

    handleFormValidation: function (fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },

    setStagingLocationError: function () {
      this.stagingLocationError = !this.defaultStagingLocation
        ? "This field is required"
        : null;
    },

    submitConfiguration: function () {
      this.$v.$touch();
      this.setStagingLocationError();
      if (!this.$v.$invalid && !this.stagingLocationError) {
        const data = this.formData;
        const payload = {
          type: "putaway",
          warehouse_id: this.warehouseDetails.index,
          default_location_id: this.defaultStagingLocation,
          configurations: [
            {
              key: "is_scan_each_unit_when_putaway",
              value: data.is_scan_each_unit_when_putaway,
              apply_rule_type:
                data.is_scan_each_unit_when_putaway_apply_rule_type,
              customers:
                data.is_scan_each_unit_when_putaway_apply_rule_type === "all"
                  ? []
                  : data.is_scan_each_unit_when_putaway_customers,
            },
            {
              key: "is_allow_putaway_across_zones",
              value: data.is_allow_putaway_across_zones,
              apply_rule_type:
                data.is_allow_putaway_across_zones_apply_rule_type,
              customers:
                data.is_allow_putaway_across_zones_apply_rule_type === "all"
                  ? []
                  : data.is_allow_putaway_across_zones_customers,
            },
            {
              key: "is_allow_to_split_putaway_task",
              value: data.is_allow_to_split_putaway_task,
              apply_rule_type:
                data.is_allow_to_split_putaway_task_apply_rule_type,
              customers:
                data.is_allow_to_split_putaway_task_apply_rule_type === "all"
                  ? []
                  : data.is_allow_to_split_putaway_task_customers,
            },
            {
              key: "should_preserve_tote_after_putaway",
              value: data.should_preserve_tote_after_putaway,
              apply_rule_type: "all",
              customers: [],
            },
          ],
        };
        this.$store.commit(SET_PAGE_LOADING, true);
        return new Promise(() => {
          ApiService.post(
            `/warehouse_management/main_configuration/store`,
            payload
          )
            .then(() => {
              Swal.fire({
                title: "Updated",
                text: "The Configuration settings are updated",
                icon: "success",
                showConfirmButton: false,
                timer: 2500,
              });
              // this.expanded = false;
            })
            .catch()
            .finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
        });
      }
    },
    searchWarehouseLocation() {
      setTimeout(() => {
        if (this.warehouseSearch) {
          this.warehouseLoading = true;
          const payload = {
            warehouse_id: this.warehouseDetails.index,
            q: this.warehouseSearch,
            location_type: "missing",
          };
          new Promise((resolve) => {
            ApiService.post(`/wms/warehouses/search-location`, payload).then(
              ({ data }) => {
                this.serverData.locations = data.locations;
                this.warehouseLoading = false;
                resolve();
              }
            );
          });
        }
      }, 200);
    },
    clearDefaultMissingLocation() {
      if (!this.warehouseSearch && !this.defaultStagingLocation) {
        this.serverData.locations = [];
      }
    },
  },
  computed: {
    is_scan_each_unit_when_putaway_apply_rule_typeErrors: function () {
      return this.handleFormValidation(
        "is_scan_each_unit_when_putaway_apply_rule_type",
        this
      );
    },
    is_scan_each_unit_when_putaway_customersErrors: function () {
      return this.handleFormValidation(
        "is_scan_each_unit_when_putaway_customers",
        this
      );
    },
    is_allow_putaway_across_zones_apply_rule_typeErrors: function () {
      return this.handleFormValidation(
        "is_allow_putaway_across_zones_apply_rule_type",
        this
      );
    },
    is_allow_putaway_across_zones_customersErrors: function () {
      return this.handleFormValidation(
        "is_allow_putaway_across_zones_customers",
        this
      );
    },
    is_allow_to_split_putaway_task_apply_rule_typeErrors: function () {
      return this.handleFormValidation(
        "is_allow_to_split_putaway_task_apply_rule_type",
        this
      );
    },
    is_allow_to_split_putaway_task_customersErrors: function () {
      return this.handleFormValidation(
        "is_allow_to_split_putaway_task_customers",
        this
      );
    },
  },
  watch: {
    defaultStagingLocation: function () {
      this.setStagingLocationError();
    },
  },
};
</script>
